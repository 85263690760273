import '@/style/main.scss'
import $ from 'jquery'
import Swiper from 'swiper'
import './home.scss'

const bannerSwiper = new Swiper('.banner', {
  autoplay: false,
  effect: 'fade',
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  },
  on: {
    slideChangeTransitionEnd: () => {
      $('.banner-leave').removeClass('banner-leave')
      $('.banner-panels').eq(bannerSwiper.activeIndex).addClass('banner-leave')
    }
  }
})

$('.banner-panels').eq(0).addClass('banner-leave')

// const bannerSwiperLoop = setInterval(() => {
//   const index = bannerSwiper.activeIndex + 1 > 2 ? 0 : bannerSwiper.activeIndex + 1
//   bannerSwiper.pagination.bullets[index].click()
// }, 4500)

// for (let i = 0; i < bannerSwiper.pagination.bullets.length; i++) {
//   bannerSwiper.pagination.bullets[i].onmouseover = function () {
//     this.click()
//   }
// }

$('.monitor-tab li').mouseenter(function (e) {
  const index = $('.monitor-tab li').index(this)
  const top = index * 114
  $('.monitor-slide').css('top', top)
  $('.tab-active').removeClass('tab-active')
  $(this).addClass('tab-active')
  $('.monitor-detail__active').removeClass('monitor-detail__active')
  $('.monitor-detail__item').eq(index).addClass('monitor-detail__active')
})

$('.btn-video').click(() => {
  $('.video').css('display', 'block')
  $('body').css('overflow', 'hidden')
  document.getElementById('video-player').play()
})

$('.video-close').click(() => {
  const videoPlayer = $('.video-player')
  $('.video').css('display', 'none')
  $('body').css('overflow', 'scroll')
  document.getElementById('video-player').pause()
})
